<template>
  <div class="video-container">
    <!-- <video id="intro-video" class="video-js vjs-fluid">
      <source
        src="https://storage.googleapis.com/vvbmpopupshop/dumbo/cam1/cam1_2.m3u8"
        type="application/x-mpegURL"
      />
    </video> -->
    <div class="controller" id="bottomInfo">
      <span class="record-icon">
        <div class="dot"></div>
        REC
      </span>
      <span class="record-time"
        ><span>{{ displayHour }}</span
        >:<span>{{ displayMin }}</span
        >:<span>{{ displaySec }}</span
        >:<span>{{ displayMSec }}</span></span
      >

      <span class="cam-info">CAM 1</span>
    </div>
  </div>
</template>
<script>
//import videojs from "video.js";
import { ref } from "vue";
// import $ from "jquery";
export default {
  setup() {
    // const startUnixTime = 1646039401000;

    let displayHour = ref("20");
    let displayMin = ref("22");
    let displaySec = ref("03");
    let displayMSec = ref("06");

    // function updateTime() {
    //   const uTimeOffset = Date.now() - startUnixTime;
    //   displayHour.value = Math.floor(uTimeOffset / (60 * 60 * 1000))
    //     .toString()
    //     .padStart(2, "0");
    //   displayMin.value = Math.floor((uTimeOffset / 1000 / 60) % 60)
    //     .toString()
    //     .padStart(2, "0");
    //   displaySec.value = Math.floor((uTimeOffset / 1000) % 60)
    //     .toString()
    //     .padStart(2, "0");
    //   displayMSec.value = Math.floor((uTimeOffset % 1000) / 10)
    //     .toString()
    //     .padStart(2, "0");
    // }

    // let m_player = ref(null);
    // onMounted(() => {
    //   setInterval(() => {
    //     updateTime();
    //   }, 197);
     /* m_player.value = videojs("intro-video", {
        autoplay: true,
        muted: true,
        loop: true,
        fluid: true,
      });*/

      // $("#intro-video").on("contextmenu", function (e) {
      //   e.preventDefault();
      // });
      // let firsttime = true;
      // m_player.on("loadedmetadata", function () {
      //   if (firsttime) {
      //     this.currentTime(1800);
      //     firsttime = false;
      //     //setInterval(updateTime, 168);
      //   }
      // });
  //  }
  //   );
  //   onBeforeUnmount(() => {
  //     if (m_player.value) {
  //       m_player.value.dispose();
  //     }
  //   });
    return { displayHour, displayMin, displaySec, displayMSec };
  },
};
</script>
<style lang="scss" scoped>
@import url("video.js/dist/video-js.css");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
.video-container {
  width: 100vw;
  height: calc(100vw / 1.3334);
  position: relative;
  font-family: "VT323", monospace;
  background-image: url("https://storage.googleapis.com/vvbmpopupshop/dumbo/cam1/3jisa-l.png");
  background-size: cover;
  background-repeat: no-repeat;
  .controller {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 0;
    background-color: #00000079;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    .record-icon {
      font-size: 22px;
      padding-left: 20px;
      div.dot {
        text-shadow: none;
        display: inline-block;
        margin-right: 0.05em;
        background: red;
        border-radius: 50%;
        width: 12px;
        height: 12px;

        animation: blinker 0.8s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
      }
    }
    .record-time {
      font-size: 20px;

      font-family: "Space Mono", monospace;
    }
    .cam-info {
      padding: 0 3px;
      margin-right: 20px;
      background-color: #ccc;
      font-family: "Maven Pro", sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: #000000d0;
    }
    .battery {
      border-radius: 12px;
      padding: 0 5px;
      margin-right: 20px;
      border: 1px #ccc solid;
      .dot {
        margin: 0 3px;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #ccc;
        border: 1px solid #ddd;
        &.low {
          background-color: transparent;
        }
      }
    }
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (min-width: 959.87px) {
  .video-container {
    width: 640px;
    height: 480px;
  }
}
</style>