<template>
  <div style="width: 100vw; max-height: 100vh">
    <div class="cctv">
      <div class="row1">
        <div class="cam">
          <video-container />
        </div>
      </div>
      <div class="row2">
        <div
          class="cam-pause"
          @click="
            showDetail(
              'https://storage.googleapis.com/vvbmpopupshop/dumbo/cam2.jpg'
            )
          "
          style="
            background-image: url('https://storage.googleapis.com/vvbmpopupshop/dumbo/cam2.jpg');
          "
        ></div>
        <div
          class="cam-pause"
          @click="
            showDetail(
              'https://storage.googleapis.com/vvbmpopupshop/dumbo/cam3.jpg'
            )
          "
          style="
            background-image: url('https://storage.googleapis.com/vvbmpopupshop/dumbo/cam3.jpg');
          "
        ></div>
        <div
          class="cam-pause"
          @click="
            showDetail(
              'https://storage.googleapis.com/vvbmpopupshop/dumbo/cam4.jpg'
            )
          "
          style="
            background-image: url('https://storage.googleapis.com/vvbmpopupshop/dumbo/cam4.jpg');
          "
        ></div>
      </div>
      <div class="info">
        <div class="instruct">
          <h3>你可曾在月光下和魔鬼共舞？</h3>
          <h3>畫面中的人是傑克，他犯了個錯，所以我給了他一點懲罰。</h3>
          <h3>
            跟你玩一個遊戲，你能夠告訴我，我藏在現場的五個線索嗎？ <br />
            <span>（猜五部電影）</span>
          </h3>
        </div>
        <p>
          活動說明：<br />
          活動作答將於3/5-3/6 VERVE the Batman快閃店現場進行，<br />
          時間：3/5 13:00-19:00、3/6 13:00-17:30 <br />
          地點：台北市南港區玉成街25巷2號
        </p>
        <p>
          凡消費即可獲得答題資格，<br />
          回答問題將以紙本方式作答並投入摸彩箱。<br />
          每天活動後將抽出三位，兩天共六位。<br />
          抽中者，將按照答案中正確線索的數量獲得相應的獎品，
        </p>
        <p>
          5個線索全對，可獲得<a
            target="_blank"
            href="https://www.verve.com.tw/categories/%E8%9D%99%E8%9D%A0%E4%BF%A0%E5%B0%88%E5%8D%80"
            >全套蝙蝠俠商品</a
          >，市價超過一萬元。<br />
          （全套的定義為每款一色，共8件上衣，1件長褲，3個配件，無法指定顏色）
        </p>
        <p>
          3-4個線索，可獲得<a
            target="_blank"
            href="https://www.verve.com.tw/categories/%E8%9D%99%E8%9D%A0%E4%BF%A0%E5%B0%88%E5%8D%80"
            >蝙蝠俠所有Tshirt款式</a
          >加蝙蝠俠Vengeance毛巾，市價超過七千元。（所有款式的定義為每款一色，共8件上衣，無法指定顏色）
        </p>
        <p>
          1-2個線索，可獲得<a
            target="_blank"
            href="https://www.verve.com.tw/categories/%E8%9D%99%E8%9D%A0%E4%BF%A0%E5%B0%88%E5%8D%80"
            >蝙蝠俠全套配件</a
          >加1件Tshirt（可指定款式）
        </p>
        <p>主辦單位保有修改、變更或中止活動之權利。</p>
      </div>
    </div>
    <div id="img-detail">
      <div
        style="
          position: absolute;
          top: 30px;
          right: 30px;
          font-size: 30px;
          color: #cccfd1da;
          font-weight: 400;
        "
      >
        X
      </div>
    </div>
  </div>
</template>

<script>
import VideoContainer from "./VideoContainer";
export default {
  name: "PuzzleApp",
  components: { VideoContainer },
  setup() {
    function showDetail(url) {
      console.log(url);
      let detail = document.getElementById("img-detail");
      detail.style.display = "block";
      detail.style.backgroundImage = "url(" + url + ")";
      detail.addEventListener("click", () => {
        document.getElementById("img-detail").style.display = "none";
      });
    }
    return { showDetail };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noticia+Text:ital@1&display=swap");
body {
  background: #101618;
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
}
a {
  text-underline-offset: 0.6ex;
  color: #ffbab0;
}

.cctv {
  max-width: 960px;
  margin: 55px auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  color: white;
  .row1 {
    width: 100%;
    .cam {
      width: 100%;
    }
  }
  .row2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    .cam {
      position: relative;
    }
    .cam-pause {
      $gap: 6px;
      text-align: center;
      margin: $gap * 2 0;
      width: calc(100vw / 3 - $gap * 3);
      height: calc((100vw / 3 - $gap * 3) * 1.33);
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        margin: auto;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  .info {
    margin: 20px;
    text-align: center;
    .instruct {
      padding: 1em 1em;
      text-align: justify;
      line-height: 1.5em;
      border-top: 1px solid #eceff1;
      border-bottom: 1px solid #eceff1;
      margin-top: 1em;
      h3 {
        text-align: justify;
        font-weight: 500;
        color: #eceff1;
        letter-spacing: 0.013em;
        span {
          font-size: 18px;
          color: #b0bec5;
          font-weight: 400;
        }
      }
    }
    p {
      text-align: justify;
      font-size: 18px;
      color: #b0bec5;
      letter-spacing: 0.08em;
      margin: 1.5em 0;
      &.quote {
        font-family: "Noticia Text", serif;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
#img-detail {
  display: none;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000077;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000de;

  background-image: url(https://storage.googleapis.com/vvbmpopupshop/dumbo/cam4.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 8;
}
@media (min-width: 959.87px) {
  .cctv {
    justify-content: center;
    .row1 {
      width: 640px;
      .cam {
        width: 640px;
        height: 480px;
      }
    }
    .row2 {
      $gap: 5px;
      width: 213.4px;
      padding: 0 $gap;
      .cam-pause {
        width: 213.4px;
        height: 160px;
        margin: calc($gap / 2) $gap;
      }
    }
    .info {
      .instruct {
        padding: 1em 5em;
      }
      p.quote {
        font-size: 18px;
      }
    }
  }
}
</style>
